import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect } from 'react';

import { getHasVitalityPromoCode } from '../nextUtils/generalUtils';
import { fetchMedicationAvailability } from '../nextUtils/glp1/fetchMedicationAvailability';
import { ErrorTracking } from '../common/errorTracking';
import {
  MainContextType,
  MedicationAvailability,
} from '@components/context/MainContextType';

const outOfStockMessage =
  'Currently out of stock, expected to return next month.';

export const defaultAvailability: MedicationAvailability = {
  wegovy: { isAvailable: true, message: '' },
  mounjaro: { isAvailable: true, message: '' },
};

/**
 * Hook to check which of our medication-supported programmes are available.
 *
 * Only used to set this value within MainContext.
 */
export const useAvailableMedication = (
  setState: Dispatch<SetStateAction<MainContextType>>,
): void => {
  const { locale } = useRouter();
  const isVitalityUser = getHasVitalityPromoCode();

  useEffect(() => {
    const setAvailability = async () => {
      try {
        const medicationAvailability = await fetchMedicationAvailability();

        const allowMedicationSupportedSignups =
          locale === 'uk' && !isVitalityUser;

        const isWegovyAvailabile = !!medicationAvailability?.find(
          ({ medication }) => medication === 'Wegovy',
        )?.hasAvailability;

        const canSelectWegovy =
          isWegovyAvailabile && allowMedicationSupportedSignups;

        const isMounjaroAvailable = !!medicationAvailability?.find(
          ({ medication }) => medication === 'Mounjaro',
        )?.hasAvailability;

        const canSelectMounjaro =
          isMounjaroAvailable && allowMedicationSupportedSignups;

        setState(prevState => ({
          ...prevState,
          medicationAvailability: {
            wegovy: {
              isAvailable: canSelectWegovy,
              message: canSelectWegovy ? '' : outOfStockMessage,
            },
            mounjaro: {
              isAvailable: canSelectMounjaro,
              message: canSelectMounjaro ? '' : outOfStockMessage,
            },
          },
        }));
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          medicationAvailability: {
            wegovy: { isAvailable: false, message: outOfStockMessage },
            mounjaro: { isAvailable: false, message: outOfStockMessage },
          },
        }));

        ErrorTracking.track(error, {
          message: 'Error fetching medication availability',
        });
      }
    };

    setAvailability();
  }, [locale, isVitalityUser, setState]);
};
